import React from "react";
import ThreeColumnGridCard, {ThreeColumnGridCardProp} from "./GridCard/ThreeColumnGridCard";

interface Props {
  cards: ThreeColumnGridCardProp[]
}

const ThreeColumnGridCards: React.FC<Props> = ({ cards }) => {
  return (
    <div className="px-8 flex flex-wrap justify-start md:gap-x-8 xl:gap-x-10 gap-y-16">
      {
        cards.map((card, index) => (
          <ThreeColumnGridCard
            {
              ...card
            }
            key={index}
          />
        ))
      }
    </div>
  );
};

export default ThreeColumnGridCards;
