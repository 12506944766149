import * as React from "react";
import Hero from "../components/common/heroTwo";
import Layout from "../components/common/layout";
import Divider from "../components/common/divider";
import ThreeColumnGridCards from "../components/grid/ThreeColumnGridCards";
import { gridCards } from '../contents/homepage'
import Meta from "../components/common/meta/SEO";
// import NewsSection from "../components/home/news";

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <Divider />
      <h2 className="px-8 dx-h2 text-dsc-theme-muted-500 leading-[46px] mx-auto max-w-[845px] mb-16 text-center">クッキー使用許諾管理のための迅速、安全、かつコンプライアンスに準拠したソリューション</h2>
      <ThreeColumnGridCards cards={gridCards} />
      <Divider />
      {/* <NewsSection />
      <Divider /> */}
    </Layout>
  );
};

export default IndexPage;

export const Head = ({ location }) => {
  const meta = {
    title: "Secure Privacy（セキュアプライバシー）｜正規販売代理店 DXable",
    description: "Secure Privacy（セキュアプライバシー）は、プライバシーに関する訪問者による設定変更、プライバシーポリシー、クッキー宣言、クッキー同意管理において、最新の各国の規制に対応したビジネスを支援します。Secure Privacyで貴社サイトをGDPR、CCPA、LGPDに対応させましょう。",
    pathname: location.pathname,
  }
  return (<Meta meta={meta} />)
}