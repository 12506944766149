import React from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import Link from "gatsby-link"

export type ThreeColumnGridCardProp = {
  title: string;
  subTitle?: string,
  cardText: string,
  to: string,
  linkText: string,
  image?: React.ReactNode
};

const ThreeColumnGridCard = ({
  title,
  subTitle,
  cardText,
  to,
    linkText, 
    image
}: ThreeColumnGridCardProp) => {
    return (
        <div className="w-full md:w-[30%] flex flex-col">
            <div className="flex items-center gap-x-3 mb-6 min-h-14">
                {image && (<div className="min-w-[32px]">{image}</div>)}
                <h4 className="text-2xl font-bold leading-8 text-dsc-theme-muted-500">{title}</h4>
            </div>
            {subTitle && (
                <p className="mb-2 text-lg leading-6 font-bold text-dsc-theme-muted-500">{subTitle}</p>
            )}
            <p className="mb-4 text-base leading-6 text-dsc-theme-muted-500 ">{ cardText }</p>
            <Link to={to} className="flex items-center gap-2 transition-all duration-300 group text-base leading-5
      [&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-white [&_svg]:bg-dsc-theme-primary [&_svg]:p-2 [&_svg]:rounded-full [&_svg]:transition-all [&_svg]:duration-300 [&_svg]:hover:scale-125 font-bold text-dsc-theme-primary">{ linkText } <ArrowRightIcon /></Link>
        </div>
    );
};

export default ThreeColumnGridCard;