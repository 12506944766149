import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const gridCards = [
    {
        title: "クッキー同意バナー",
        cardText: "スタイリッシュなクッキーバナーを設置し、国際的なクッキー法に準拠したサイトを維持することができます。",
        to: "/feature/cookie-banner/",
        linkText: "詳しくはこちら",
        image: (<StaticImage title='クッキーコンセントバナー' quality={100} alt='Icon1' src='../../static/images/home/icon1.svg' />)
    },
    {
        title: "ポリシージェネレーター",
        cardText: "GDPR、CCPA、その他の規制要件に準拠したクッキープライバシーポリシーを迅速に作成できます。",
        to: "/feature/cookie-privacy-policy/",
        linkText: "詳しくはこちら",
        image: (<StaticImage title='クッキー＆プライバシーポリシー' quality={100} alt='Icon2' src='../../static/images/home/icon2.svg' />)
    },
    {
        title: "クッキー／ウェブサイトスキャナー",
        cardText: "あなたのサイト上の全てのクッキーとトラッカーをスキャンし、ウェブサイトを常に最新の状態に保てます。",
        to: "/feature/cookie-ssl-scanner/",
        linkText: "詳しくはこちら",
        image: (<StaticImage title='Cookie & SSL スキャナー' quality={100} alt='Icon3' src='../../static/images/home/icon3.svg' />)
    },
    {
        title: "プリファレンスセンター",
        cardText: "あなたのウェブサイトの訪問者がクッキーをオプトイン／オプトアウトできるようにし処理を完全自動化できます。",
        to: "/feature/preference-center/",
        linkText: "詳しくはこちら",
        image: (<StaticImage title='プリファレンスセンター' quality={100} alt='Icon4' src='../../static/images/home/icon4.svg' />)
    },
    {
        title: "同意ログ",
        cardText: "クッキーバナーから収集したユーザーの同意を文書化し、ユーザーの同意・拒否の回数を確認することができます。",
        to: "/feature/consent-log/",
        linkText: "詳しくはこちら",
        image: (<StaticImage title='コンセントログ' quality={100} alt='Icon5' src='../../static/images/home/icon5.svg' />)
    },
    {
        title: "70以上の言語サポート",
        cardText: "Secure Privacyのサポート言語は70以上。全てフルカスタマイズ可能で、開発者でなくとも編集が可能です。",
        to: "/feature/70language-support/",
        linkText: "詳しくはこちら",
        image: (<StaticImage title='70以上の言語サポート' quality={100} alt='Icon6' src='../../static/images/home/icon6.svg' />)
    }
]