import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Button from "../button";
const Hero = () => {
  return (
    <section className="flex flex-col gap-32 px-8 pt-[88px] pb-[120px] md:py-9 font-noto justify-between md:flex-row relative">
      <div className="absolute w-full h-full top-0 left-0 -z-10">
        <StaticImage src="../../../../static/images/home/bannerBg.png" className="w-full h-full" alt="Banner Background" placeholder="none" />
      </div>
      <div className="flex flex-col justify-center text-dsc-theme-muted-500">
        <h1 className="mb-6 dx-h1-lg leading-[50px] max-w-[438px]">
          プライバシーとクッキーのコンプライアンスを自動化
        </h1>
        <p className="mb-6 dx-p1">
          GDPR、CCPA、LGPD、GOOGLE同意モード
        </p>
        <div className="max-w-max">
          <Button to="/" variant="normal">お問い合わせ</Button>
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <div className="w-full flex justify-center">
          <StaticImage
            src="../../../../static/images/home/banner.png"
            alt="Banner Image"
            width={480}
            className="-z-10"
            title="Secure Privacy Banner"
            placeholder="none"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
